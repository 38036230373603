import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaCode } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const CodingService = ({ data }) => {
  const seoData = {
    title:
      'Webページコーディング開発代行｜奈良の広告代理店向けサービス｜インヴォルブ',
    description:
      'デザインはできるがコーディングができない広告代理店様向けに、HTML/CSS/JavaScriptのコーディングを代行。システムが絡む案件についても仕様の整理から対応可能。',
    pageUrl: 'https://www.involve-in.jp/service/coding',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'Webページコーディング開発代行', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes;

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="Webページコーディング開発代行"
        subtitle="デザインをコードに"
        icon={FaCode}
        description="デザインはできるがコーディングができない広告代理店様向けに、HTML/CSS/JavaScriptのコーディングを代行。システムが絡む案件についても仕様の整理から対応可能。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: 'デザイン通りの忠実なコーディング',
            description:
              '細かなデザインの再現性を重視し、正確なコーディングを提供。',
          },
          {
            title: 'システム開発の相談も可能',
            description: 'CMS導入や動的コンテンツ対応もサポート。',
          },
          {
            title: '奈良県の広告代理店で多数実績あり',
            description: '地域の広告代理店向けの豊富な経験と対応実績。',
          },
        ]}
        flow={[
          {
            title: 'デザインデータの確認',
            duration: '1日',
            description:
              '提供いただいたデザインデータ（Figma, PSD, XD など）を確認。',
          },
          {
            title: '仕様・機能要件のヒアリング',
            duration: '2日〜',
            description: '必要な機能やアニメーションの要件をヒアリング。',
          },
          {
            title: 'コーディング開始',
            duration: '1週間〜',
            description: 'デザインを基にHTML/CSS/JavaScriptで忠実に再現。',
          },
          {
            title: '動作テスト・納品',
            duration: null,
            description: '各デバイスでの表示確認を行い、完成データを納品。',
          },
        ]}
        preparation={[
          {
            title: 'デザインデータの準備',
            description: 'Figma・PSD・XDなどのデザインデータをご用意ください。',
          },
          {
            title: '仕様の確認',
            description: 'サイトの機能やインタラクションについて明確にします。',
          },
          {
            title: '参考サイトの共有',
            description:
              '仕上がりのイメージに近いサイトがあれば共有してください。',
          },
          {
            title: '納期の確認',
            description: '希望する納期があれば、事前にお知らせください。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥100,000〜"
        cta="開発を依頼する"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default CodingService;
